<template>
<!--  :footerBtnName="['取消','同步']"-->
    <r-e-dialog :title="title" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer
                :footerBtnName="['取消','提交']"  fullscreen
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <div style="height: 79vh;">
            <div style="height: 100%;width: 100%;">
                <el-steps :active="active" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
                    <el-step title="申请-管家申请">
                        <div slot="description">
                            <div>申请人: {{ submitUserName }}</div>
                            <div v-if="active > 0">申请时间: {{ submitTime }}</div>
                        </div>
                    </el-step>
                    <el-step title="片管员预审">
                        <div slot="description" v-if="active>=1">
                            <div>确认人: {{ disposeUserName }}</div>
                            <div v-if="active >= 2">确认时间: {{ disposeDate }}</div>
                        </div>
                    </el-step>
                    <el-step title="负责人审核">
                        <div slot="description" v-if="active>=2">
                            <div>确认人: {{ personChargeUserName }}</div>
                            <div v-if="active >= 3">确认时间: {{ personChargeDate }}</div>
                        </div>
                    </el-step>
                    <!--                    v-if="checkOurData.totalAmount < 0"-->
                    <el-step title="财务部审核">
                        <div slot="description" v-if="active >= 3">
                            <div>处理人: {{ remittanceUserName }}</div>
                            <div v-if="active >= 4">处理时间: {{ handleDate }}</div>
                        </div>
                    </el-step>
                    <el-step title="查看"></el-step>
                </el-steps>
                <div style="height: 90%;display: flex;flex-flow: column wrap;">


                    <div class="boder-right width33b padding_0_30">
                        <div class="title">当前有效合同</div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <!-- <div class="flex justify-between row bg-E2F5FF" style="margin-bottom:0;">
                            <div class="text-left width200" style="margin-right:10px">合同编号</div>
                            <div class="text-left width300" style="margin-right:10px">房源</div>
                            <div class="text-left width300">合同周期</div>
                        </div> -->
                        <!-- <div class="flex justify-between row " style="margin-bottom:0;border-top:0px"  v-for="(item, index) in workOrderContractVoList"  :key="index">
                            <div class="text-left width200" style="margin-right:5px">{{item.contractNum}}</div>
                            <div class="text-left width300" style="margin-right:10px">{{item.apartmentName}}</div>
                            <div class="text-left width300">{{item.contractCycle}} </div>
                        </div> -->
                        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLeasorTableData" :columns="tableColumn"
                                    :height="400"  >

                            <template slot="empty">
                                <el-empty/>
                            </template>
                        </r-e-table>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="title">租户信息</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">{{ checkOurData.leasorName }}</div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E6E6E6">申请时间    {{ transferDate }} </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="title">现有余额</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">余额类型</div>
                            <div class="text-right width100">合计(元)</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">押金余额</div>
                            <div class="text-right width100">{{depositBalanceTransferBeforeAmount}}</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="title">转移余额</div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">余额类型</div>
                            <div class="text-right width100">合计(元)</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100"><el-link type="primary">房租余额</el-link></div>
                            <div class="text-right width100">
                                <span v-if="!rentalShow" @click="amountClick('rentalShow', 'transferRentalBalanceAmount')" style="color:rgb(64, 158, 255)">
                                    {{ checkOurData.transferRentalBalanceAmount }}    
                                </span>
                                <el-input v-else v-model="checkOurData.transferRentalBalanceAmount" ref="transferRentalBalanceAmount" type="number"  color="red" size="mini"
                                          @blur="amountBlur('rentalShow', 'transferRentalBalanceAmount')" placeholder="请输入内容"/>
                            </div>
                            <!-- <div class="text-right width100"><el-link type="primary">100.00</el-link></div> -->
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100"><el-link type="primary">杂费余额</el-link></div>
                            <div class="text-right width100">
                                <span v-if="!otherShow" @click="amountClick('otherShow', 'transferOtherBalanceAmount')" style="color:rgb(64, 158, 255)">
                                    {{ checkOurData.transferOtherBalanceAmount }}
                                </span>
                                <el-input v-else v-model="checkOurData.transferOtherBalanceAmount" ref="transferOtherBalanceAmount" type="number" size="mini"
                                          @blur="amountBlur('otherShow', 'transferOtherBalanceAmount')" placeholder="请输入内容"/>
                            </div>
                        </div>
                    </div>
                    <div class="flex align-baseline width33b padding_10_30 fw600" style="color:red">
                        <div style="width: 50px;">总计:</div>
                        <div>
                            <div style="font-size: 24px;">{{ totalAmount }}</div>
                            <div  style="font-size: 12px;">含房租余额{{ checkOurData.transferRentalBalanceAmount }},杂费余额{{ checkOurData.transferOtherBalanceAmount }}</div>
                        </div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="title"> 剩余余额</div>
                    </div>
                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-E2F5FF">
                            <div class="text-left width100">余额类型</div>
                            <div class="text-right width100">合计(元)</div>
                        </div>
                    </div>

                    <div class="boder-right width33b padding_0_30">
                        <div class="flex justify-between row bg-F8F8F8">
                            <div class="text-left width100">押金余额</div>
                            <div class="text-right width100">{{depositBalanceTransferAfterAmount}}</div>
                        </div>
                    </div>
                    <div style="width: 50%;padding: 10px 30px;">
                        <div style="font-weight: 600;padding-bottom: 10px">备注</div>
                        <el-input type="textarea" v-model="checkOurData.remark" :rows="3" style="width: 100%;"
                                  placeholder="请输入内容" :disabled="this.state!==0"/>
                    </div>

                    <div class="width33b padding_10_30">
                        <el-badge :value="checkOutDisposeLog.length">
                            <el-button type="primary" @click="handleRecordSwitch=true">处理记录</el-button>
                        </el-badge>
                    </div>
                </div>
            </div>
        </div>

        <r-e-dialog :title="billTitle" :visible.sync="billSwitch" show-footer top="5vh" width="1000px"
                    :showFooter="false" @click-cancel="billListClose" v-dialog-drag>
            <r-e-table ref="tableRef" :columns="houseBillColumns" :data="billList" :height="500" :showPagination="false"
                       show-summary :summary-method="getSummaries">
                <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
                <!--<el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="update(row)">修改</span>
                        </div>
                    </template>
                </el-table-column>-->
            </r-e-table>
        </r-e-dialog>
        <el-button  slot="footer" v-if="state===0" type="danger" @click="cancellationWorkOrder">作废</el-button>
        <el-button v-if="state!==0" slot="footer" type="info" ref="bh" @click="rejectFun">驳回</el-button>
        <el-button v-else slot="footer" class="submit" @click="saveWorkOrder">保存</el-button>
<!--        <el-button slot="footer" type="info" ref="bh" @click="clickSubmit">提交</el-button>-->
        <r-e-dialog title="驳回" :visible.sync="rejectShow" show-footer top="5vh" showFooter width="500px"
                    @click-submit="rejectSubmit" @click-cancel="rejectCancel" @close="rejectCancel" v-dialog-drag>
            <el-form ref="rejectForm" :model="rejectForm" label-width="80px" size="small" :rules="rejectRules">
                <el-form-item label="驳回原因" prop="reason">
                    <el-input type="textarea" v-model="rejectForm.reason" placeholder="请输入驳回原因" :rows="4"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>

        <r-e-dialog title="处理记录" :visible.sync="handleRecordSwitch" show-footer top="5vh" width="1000px"
                    :showFooter="false" @click-cancel="handleRecordSwitch=false" v-dialog-drag>
            <r-e-table ref="tableHandleRecordRef" :columns="handleRecordColumns" :data="checkOutDisposeLog"
                       :height="500" border
                       :showPagination="false"/>
        </r-e-dialog>
    </r-e-dialog>
</template>

<script>
import {MessageInfo, MessageSuccess} from "@custom/message";
import {timeFormat, numberFormat} from "@custom/index";
import {
    getWordInfo, wordDispose, wordAudit, checkoutAuditRejectionApi,newobjgongdanApi,
    getTransferWorkOrder,getTransferWorkOrderInfo,getSubmitTransferWorkOrder,cancellationTransferWorkOrderInfo,
    transferWorkRejectionApi,getContractPageApi
} from "@/api/work-order-management";
import {contractCheckoutExit} from "@/api/contract";
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import {houseBillColumns} from "@/views/rental-management/house-resource/data";
import {contracColumns} from "@/views/rental-management/tenant-management/data";
import {getPaybillUuidsList,synchronizationapi} from "@/api/bill";
// video-upload-perfect
export default {
    name: "dialog-check-out-work-order-handle",
    data() {

        return {
            tableColumn: contracColumns(this),
            submitUserName:null, // 申请人
            disposeUserName:null,//	片管审核人
            personChargeUserName:null,// 	负责人审核人
            remittanceUserName:null, // 财务部审核人
            workOrderContractVoList:[],//当前有效合同列表
            depositBalanceTransferBeforeAmount:null,// 押金余额-现有余额 转换后
            transferRentalBalanceAmount:null,// 转移余额-房租余额 转换后
            transferOtherBalanceAmount:null,// 转移余额-杂费余额 转换后
            totalAmount:null , //转移余额-总计 转换后
            depositBalanceTransferAfterAmount:null,// 剩余余额-押金余额 转换后
            checkOutDisposeLog: [],// 处理记录表格数据
            handleRecordColumns: [
                {prop: "name", label: "处理人", width: 100},
                {prop: "operatingState", label: "操作", width: 100},
                {prop: "remark", label: "驳回原因",},
                {prop: "controlsDate", label: "时间", width: 150},
            ],
            checkOurData: {
                leasorName:null,// 租户名称
                transferDate:null, //申请时间
                depositBalanceTransferBeforeAmount:null,// 押金余额-现有余额 转换前
                transferRentalBalanceAmount:null,// 转移余额-房租余额 转换前
                transferOtherBalanceAmount:null , // 转移杂费-房租余额 转换前
                depositBalanceTransferAfterAmount:null,// 剩余余额-押金余额 转换前
                totalAmount:null, // 转移余额总计 转换前
                remark:null , // 备注
            },

            dialogVisible: false,
            title: '',
            loading: false,
            uuid: null,
            otherShow: false, // 杂费余额是否可修改
            rentalShow: false, // 房租余额是否可修改
            state: null,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            billTitle: "",
            billSwitch: false,
            houseBillColumns: houseBillColumns(this),
            billList: [],
            billUuids: null,
            active: 4,
            rejectShow: false, // 驳回
            rejectForm: {
                reason: null,
            },
            rejectRules: {
                reason: [{required: true, message: '请输入驳回原因', trigger: 'blur'}]
            },

            handleRecordSwitch: false,

        };
    },
    props: {},
    methods: {
        async  getLeasorTableData(params) {
            let leasorUuid = this.leasorUuid;
            return getContractPageApi({leasorUuid});
        },
      // 上传视频事件
      handleUploadSuccess({info}) {
        // 上传视频
        this.videoUrl = info.uuid;
        this.checkOurData.videoUrl = info.uuid;
      },
       //2024/04/17 张晓瑜新增删除已上传的视频
       deleteUploadedVideo() {
        this.checkOurData.videoUrl = ''; // 清空视频 UUID
        console.log('this.formPublish.videoUrl0',this.checkOurData.videoUrl);
        // this.isUploaded = false; // 标记未上传视频
        },
        async openDialog(data) {
          console.log('data消息,',data)
            this.title = '处理';
            const {uuid, status, leasorUuid} = data;
            this.active = status;
            this.leasorUuid = leasorUuid
            this.uuid = uuid
            this.state = status;

            await this.getTransferWorkOrderInfo();
            await this.getLeasorTableData()
            this.$nextTick(() => {
                if (this.state !== 0) {
                    const bh = this.$refs['bh'].$el.parentNode.childNodes[2];
                    const qx = this.$refs['bh'].$el.parentNode.childNodes[0];
                    this.$refs['bh'].$el.parentNode.insertBefore(bh, qx);
                }
            });
        },
        // 获取工单详情信息
       async getTransferWorkOrderInfo(){
             let that = this;
             let uuid = this.uuid;
             const loadingOptions = that.loadingOptions;
             const loading = that.$loading({...loadingOptions});
             getTransferWorkOrderInfo({uuid}).then(res => {
             console.log('res',res);
             const {info} = res;
            //  this.uuid = info.uuid; //合同uuid
             this.workOrderContractVoList = info.workOrderContractVoList;  // 当前有效合同列表
             console.log('this.workOrderContractVoList',info.workOrderContractVoList);
             this.checkOurData.leasorName = info.leasorName // 租户名称
             this.checkOurData.depositBalanceTransferBeforeAmount = info.depositBalanceTransferBeforeAmount // 押金余额-现有余额
             this.depositBalanceTransferBeforeAmount = parseFloat(this.checkOurData.depositBalanceTransferBeforeAmount  / 100).toFixed(2); // 押金余额-现有余额 转换后
             this.checkOurData.transferRentalBalanceAmount = info.transferRentalBalanceAmount ;//转移余额-房租余额
             this.checkOurData.transferRentalBalanceAmount = parseFloat(this.checkOurData.transferRentalBalanceAmount / 100).toFixed(2); // 转移余额-房租余额 转换后
             this.checkOurData.transferOtherBalanceAmount = info.transferOtherBalanceAmount ;//转移余额-杂费余额
             this.checkOurData.transferOtherBalanceAmount = parseFloat(this.checkOurData.transferOtherBalanceAmount / 100).toFixed(2); // 转移余额-杂费余额 转换后
             this.checkOurData.depositBalanceTransferAfterAmount = info.depositBalanceTransferAfterAmount; //剩余余额-押金余额
             this.depositBalanceTransferAfterAmount = parseFloat(this.checkOurData.depositBalanceTransferAfterAmount  / 100).toFixed(2); // 剩余余额-押金余额 转换后
             this.checkOurData.totalAmount = info.totalAmount;
             this.totalAmount = parseFloat(this.checkOurData.totalAmount   / 100).toFixed(2); // 转移金额-总计 转换后
             this.submitUserName = info.submitUserName;  // 申请人
             this.disposeUserName = info.disposeUserName; // 片管员预审
             this.personChargeUserName = info.personChargeUserName; // 	负责人审核人
             this.remittanceUserName = info.remittanceUserName; // 	财务部审核人
            
             this.checkOurData.remark = info.remark; //备注
             console.log('this.submitUserName',this.submitUserName);
             this.checkOurData.transferDate = info.transferDate;
             this.transferDate = info.transferDate ? timeFormat(new Date(info.transferDate), "yyyy-MM-dd") : '';
             console.log('info.depositTransferDisposeLogs',info.depositTransferDisposeLogs);
             this.checkOurData.submitTime = info.submitTime;
             this.submitTime = info.submitTime ? timeFormat(new Date(info.submitTime), "yyyy-MM-dd HH:mm:ss") : ''; //	提交时间
             this.disposeDate = info.disposeDate ? timeFormat(new Date(info.disposeDate), "yyyy-MM-dd HH:mm:ss") : ''; //	片管审核时间
             this.personChargeDate = info.personChargeDate ? timeFormat(new Date(info.personChargeDate), "yyyy-MM-dd HH:mm:ss") : ''; //负责人审核时间


            // 处理记录表格数据
             this.checkOutDisposeLog = info.depositTransferDisposeLogs;
             if (this.checkOutDisposeLog.length > 0) {
                this.checkOutDisposeLog = this.checkOutDisposeLog.map(item => {
                    let {controlsDate} = item;
                    controlsDate = controlsDate ? timeFormat(new Date(controlsDate), "yyyy-MM-dd HH:mm:ss") : null;
                    return {...item, controlsDate}
                });
            }
             console.log('this.handleRecordColumns',this.handleRecordColumns);
             this.dialogVisible = true;
            }).finally(() => loading.close());
       },
        // 保存押金工单
        saveWorkOrder() {
            let that = this;
            let leasorUuid = that.leasorUuid;
            let uuid = that.uuid;
     
            const { leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount,
                    transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,totalAmount} = that.checkOurData
            this.transferRentalBalance = (transferRentalBalanceAmount * 100).toFixed(2);
            this.transferRentalBalanceAmount = parseFloat(this.transferRentalBalance);
            this.transferOtherBalance = (transferOtherBalanceAmount * 100).toFixed(2);
            this.transferOtherBalanceAmount = parseFloat(this.transferOtherBalance);
            console.log('totalAmount',totalAmount);
            let data = {
                    leasorUuid,uuid,
                    leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount:this.transferRentalBalanceAmount,
                    transferOtherBalanceAmount:this.transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,status:0,totalAmount
            }
            console.log('保存数据',data);
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            // //调用接口保存押金工单
            getSubmitTransferWorkOrder(data).then(res => {
                console.log('res',res);
                loading.close();
                MessageSuccess('保存成功');
                //调用取消方法，关闭弹窗
                that.clickCancel();
            }).catch(() => loading.close());
        },
        // 提交押金工单
        clickSubmit() {
            let that = this;
            let leasorUuid = that.leasorUuid;
            let uuid = that.uuid;
            if (that.state !== 0) {
                that.transferWorkRejection({byRejection: true, uuid, rejection: null});
                return;  // 确保不继续执行下面的代码
            }
            const { leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount,
                    transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,totalAmount} = that.checkOurData
            this.transferRentalBalance = (transferRentalBalanceAmount * 100).toFixed(2);
            this.transferRentalBalanceAmount = parseFloat(this.transferRentalBalance);
            this.transferOtherBalance = (transferOtherBalanceAmount * 100).toFixed(2);
            this.transferOtherBalanceAmount = parseFloat(this.transferOtherBalance);
            let data = {
                    leasorUuid,uuid,
                    leasorName,transferDate,depositBalanceTransferBeforeAmount,transferRentalBalanceAmount:this.transferRentalBalanceAmount,
                    transferOtherBalanceAmount:this.transferOtherBalanceAmount,depositBalanceTransferAfterAmount,remark,status:1,totalAmount
            }
            console.log('保存数据',data);
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            // //调用接口保存押金工单
            getSubmitTransferWorkOrder(data).then(res => {
                console.log('res',res);
                loading.close();
                MessageSuccess('提交成功');
                //调用取消方法，关闭弹窗
                that.clickCancel();
            }).catch(() => loading.close());
        },
        // 作废押金转移工单
        cancellationWorkOrder(){
            let that = this;
            let uuid = that.uuid;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            cancellationTransferWorkOrderInfo({uuid}).then(res => {
                console.log('res',res);
                loading.close();
                MessageSuccess('作废成功');
                //调用取消方法，关闭弹窗
                that.clickCancel();
            }).catch(() => loading.close());
        },
        // async getWordInfo() {
        //     let that = this;
        //     let uuid = this.uuid;
        //     // alert(uuid)
        //     const active = this.active;
        //     // alert(that.checkOurData.checkOutDate)
        //     console.log(that.checkOurData.checkOutDate)
        //     const loadingOptions = that.loadingOptions;
        //     const loading = that.$loading({...loadingOptions});
        //     // let {info} = await getWordInfo(uuid).catch(err => loading.close());
        //     that.deletetype = false
        //     that.disabledtype = true //默认不可以传视频
        //     // 2024-3-11-陈守亮修改数据--判断是第一步
        //   if(active === 0){
        //     let res= await newobjgongdanApi({uuid:that.contractUuid,checkOutDate:that.checkOurData.checkOutDate}).catch(err => loading.close());
        //     that.deletetype = true
        //     that.disabledtype = false  //如果是第一步基本可以传视频
        //     console.log(res)
        //     info.depositDate = res.info.depositDate
        //     info.depositAmount = res.info.depositAmount
        //     info.rentalDate = res.info.rentalDate
        //     info.rentalAmount = res.info.rentalAmount
        //     info.serviceDate = res.info.serviceDate
        //     info.serviceAmount = res.info.serviceAmount
        //     info.noPaidRentalDate = res.info.noPaidRentalDate
        //     info.noPaidRentalAmount = res.info.noPaidRentalAmount
        //     info.noPaidServiceDate = res.info.noPaidServiceDate
        //     info.noPaidServiceAmount = res.info.noPaidServiceAmount
        //     info.noPaidWaterDate = res.info.noPaidWaterDate
        //     info.noPaidWaterAmount = res.info.noPaidWaterAmount
        //     info.noPaidElectDate = res.info.noPaidElectDate
        //     info.noPaidElectAmount = res.info.noPaidElectAmount
        //   }
        //     // console.log(res.info.depositDate,info.depositDate)
        //     let {videoUrl,
        //         depositAmount, depositDate, rentalDate, rentalAmount, serviceDate, serviceAmount, rentalBalance,
        //         expenseBalance, noPaidRentalDate, noPaidRentalAmount, noPaidServiceDate, noPaidServiceAmount,
        //         noPaidWaterDate, noPaidWaterAmount, noPaidElectDate, noPaidElectAmount, aprtmentName, leasor,
        //         workPictures, comment, contractUuid, bank, cardNum, remitter, paymentMethod, reasonCode, reasonName,
        //         clean, damage, other, dedit, totalAmount, refundAmopunt, payAmount, checkOutDate,
        //         noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
        //         noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
        //         serviceUuids, depositCount, depositUuids, gzfTicket, submitUserName, submitDate = null,
        //         disposeUserName = null, disposeDate = null, remittanceUserName = null, remittanceDate = null,
        //         personChargeUserName = null, personChargeDate = null, checkOutDisposeLog = [],
        //        depoTemporaryBalance
        //     } = info;
        //     gzfTicket = gzfTicket || {}

        //     if (checkOutDisposeLog.length > 0) {
        //         checkOutDisposeLog = checkOutDisposeLog.map(item => {
        //             let {controlsDate} = item;
        //             controlsDate = controlsDate ? timeFormat(new Date(controlsDate), "yyyy-MM-dd HH:mm:ss") : null;
        //             return {...item, controlsDate}
        //         });
        //     }
        //     // console.log("gzfTicket", gzfTicket)
        //     // this.submitter = submitUserName;
        //     // this.submitDate = submitDate ? timeFormat(new Date(submitDate), "yyyy-MM-dd HH:mm:ss") : '';

        //     that.checkOutDisposeLog = checkOutDisposeLog;
        //     that.personChargeUserName = personChargeUserName ? personChargeUserName : '';
        //     that.personChargeDate = personChargeDate ? timeFormat(new Date(personChargeDate), "yyyy-MM-dd HH:mm:ss") : '';
        //     that.confirmUser = disposeUserName ? disposeUserName : '';
        //     that.confirmDate = disposeDate ? timeFormat(new Date(disposeDate), "yyyy-MM-dd HH:mm:ss") : '';
        //     that.handleUser = remittanceUserName ? remittanceUserName : '';
        //     that.handleDate = remittanceDate ? timeFormat(new Date(remittanceDate), "yyyy-MM-dd HH:mm:ss") : '';

        //     if (active === 1) {
        //         that.personChargeUserName = localStorage.getItem("userName");
        //     }
        //     if (active === 2) {
        //         that.confirmUser = localStorage.getItem("userName");
        //     }
        //     if (active === 3) {
        //         that.handleUser = localStorage.getItem("userName");
        //     }

        //     checkOutDate = timeFormat(new Date(checkOutDate));
        //     depositAmount = parseFloat((depositAmount / 100)).toFixed(2);
        //     rentalAmount = parseFloat(rentalAmount / 100).toFixed(2);
        //     serviceAmount = parseFloat(serviceAmount / 100).toFixed(2);
        //     rentalBalance = parseFloat(rentalBalance / 100).toFixed(2);
        //     expenseBalance = parseFloat(expenseBalance / 100).toFixed(2);
        //     depoTemporaryBalance = parseFloat(depoTemporaryBalance / 100).toFixed(2);
        //     noPaidRentalAmount = parseFloat(noPaidRentalAmount / 100).toFixed(2);
        //     noPaidServiceAmount = parseFloat(noPaidServiceAmount / 100).toFixed(2);
        //     noPaidWaterAmount = parseFloat(noPaidWaterAmount / 100).toFixed(2);
        //     noPaidElectAmount = parseFloat(noPaidElectAmount / 100).toFixed(2);
        //     clean = parseFloat(clean / 100).toFixed(2);
        //     damage = parseFloat(damage / 100).toFixed(2);
        //     other = parseFloat(other / 100).toFixed(2);
        //     dedit = parseFloat(dedit / 100).toFixed(2);
        //     totalAmount = parseFloat(totalAmount / 100).toFixed(2);
        //     refundAmopunt = parseFloat(refundAmopunt / 100).toFixed(2);
        //     payAmount = parseFloat(payAmount / 100).toFixed(2);
        //     let oldCheckOurData = this.checkOurData;
        //     this.checkOurData = {
        //         ...oldCheckOurData, depositAmount, depositDate, rentalDate, rentalAmount, serviceDate,
        //         serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
        //         noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
        //         noPaidElectAmount, aprtmentName, leasor, comment, contractUuid, bank, cardNum, remitter, paymentMethod,
        //         reasonCode, reasonName, clean, damage, other, dedit, totalAmount, payAmount, refundAmopunt,
        //         noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
        //         noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
        //         serviceUuids, depositCount, depositUuids, checkOutDate, gzfTicket,videoUrl,depoTemporaryBalance
        //     }
        //   // this.imgList = workPictures ? workPictures.split(";").filter(item => item !== "") : [];
        //   // 优化照片显示分割-陈守亮-2024-4-3=28
        //   if (workPictures.indexOf(",") !== -1) {
        //     console.log("字符串使用逗号分割");
        //     this.imgList = this.splitAndFilter(workPictures, ',');
        //   } else if (workPictures.indexOf(";") !== -1) {
        //     console.log("字符串使用分号分割");
        //     this.imgList = this.splitAndFilter(workPictures, ';');
        //   } else {
        //     this.imgList.push(workPictures)
        //     console.log("字符串没有逗号或分号");
        //   }
        //     this.calculationRefundAmopunt();
        //     this.calculationpPayAmount();
        //     loading.close();
        // },
       // 处理字符串函数，照片分割字符串
       splitAndFilter(workPictures, separator = ',') {
        if (typeof workPictures !== 'string') {
          console.error('workPictures should be a string');
          return [];
        }

        return workPictures.split(separator).filter(item => item);
      },

      setCheckOurDate() {
            this.checkOurDateShow = true;
            this.$nextTick(() => this.$refs["checkOurDate"].focus());
        },
        checkOurDateBlur() {
            this.checkOurDateShow = false;
            this.getCheckoutInfo();
        },
        amountBlur(name, ref) {
            this[name] = false;
            this.checkOurData[ref] = (parseFloat(this.checkOurData[ref])).toFixed(2);
            this.calculationRefundAmopunt();
            this.calculationpPayAmount();
        },
        amountClick(name, ref) {
            if (this.state !== 0) {
                return;
            }

            this[name] = true;
            this.$nextTick(() => this.$refs[ref].focus());
        },
        setReason(code, name) {
            if (this.state !== 0) {
                return;
            }
            this.checkOurData.reasonCode = code;
            this.checkOurData.reasonName = name;
        },
        // 计算转移金额
        calculationRefundAmopunt() {
            let {transferRentalBalanceAmount, transferOtherBalanceAmount} = this.checkOurData;
            transferRentalBalanceAmount = parseFloat(transferRentalBalanceAmount);
            transferOtherBalanceAmount = parseFloat(transferOtherBalanceAmount);
            console.log('1',transferRentalBalanceAmount);
            console.log('2',transferOtherBalanceAmount);
            this.totalAmount = (transferRentalBalanceAmount +transferOtherBalanceAmount).toFixed(2);
            this.checkOurData.totalAmount = (transferRentalBalanceAmount +transferOtherBalanceAmount)*100;

            console.log('this.totalAmount',this.totalAmount);
        },
        // 计算剩余余额
        calculationpPayAmount() {
            console.log('轻轻');
            let {depositBalanceTransferBeforeAmount,totalAmount} = this.checkOurData;
            console.log('总共',this.totalAmount);
            console.log('depositBalanceTransferBeforeAmount',depositBalanceTransferBeforeAmount);
            this.checkOurData.depositBalanceTransferAfterAmount = (depositBalanceTransferBeforeAmount-totalAmount);
            console.log('3',this.checkOurData.depositBalanceTransferAfterAmount);
            this.depositBalanceTransferAfterAmount = parseFloat((this.checkOurData.depositBalanceTransferAfterAmount / 100).toFixed(2));
            console.log('4',this.depositBalanceTransferAfterAmount);
        },
        clickCancel() {
            // return
          // alert('取消')
            this.$emit('transferHandleSearch');
            this.dialogVisible = false;
            this.checkOurData = {
                leasorName:null,// 租户名称
                transferDate:null, //申请时间
                depositBalanceTransferBeforeAmount:null,// 押金余额-现有余额 转换前
                transferRentalBalanceAmount:null,// 转移余额-房租余额 转换前
                transferOtherBalanceAmount:null , // 转移杂费-房租余额 转换前
                depositBalanceTransferAfterAmount:null,// 剩余余额-押金余额 转换前
                totalAmount:null, // 转移余额总计 转换前
                remark:null , // 备注
            };
            this.uuid = null;
        },

        handleSuccess({fileList}) {
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },

        handleRemove({fileList}) {
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },
        async goBill(type, data) {
            if (data) {
                let that = this;
                that.billTitle = type;
                that.billUuids = data;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                getPaybillUuidsList({uuids: data}).then(res => {
                    const {list} = res;
                    that.billList = list;
                    that.billSwitch = true;
                }).finally(() => loading.close());
            } else MessageInfo('无' + type + '账单');
        },
        update(data) {
            this.$refs["dialogAddBill"].openDialog(data);
        },
        billClickCancel() {
            const {billTitle, billUuids} = this;
            this.goBill(billTitle, billUuids);
            this.getCheckoutInfo();
        },
        billListClose() {
            this.billTitle = null;
            this.billUuids = null;
            this.billList = null;
            this.billSwitch = false;
        },
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';

            });
            return sums;
        },

        numberFormat(number) {
            return numberFormat(Number(number) / 100);
        },

        /*deletewei() {
            let that = this;
            this.$confirm('此操作将删除违约金, 是否继续?', '注意', {
                type: 'warning',
            }).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                loading.close();
                var odiv = document.getElementById("div1");
                if (odiv.style.display == "block") {
                    odiv.style.display = "none";
                }
                this.dedit = 0.00;
                this.checkOurData.dedit = '0.00'
                this.calculationpPayAmount();
                this.calculationpTotalAmount();
            }).catch(() => {
            });
        }*/

        //驳回按钮点击事件
        rejectFun() {
            this.rejectShow = true;
        },
        //同步点击事件
      async synchronization(){
          // alert('同步')
        await synchronizationapi({uuid:this.uuid}).then(res => {
          console.log(res)
          this.openDialog(res.info)
        })
        MessageSuccess('同步成功');
      },
        // 驳回取消
        rejectCancel() {
            this.rejectShow = false;
            this.rejectForm.reason = null;
        },
        // 驳回确定
        rejectSubmit() {
            const that = this;
            this.$refs['rejectForm'].validate((valid) => {
                if (valid) {
                    const rejection = that.rejectForm.reason;
                    const uuid = this.uuid;
                    const data = {byRejection: false, rejection, uuid, }
                    that.transferWorkRejection(data);
                }
            });
        },

        transferWorkRejection(data) {
            const that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            transferWorkRejectionApi(data).then(res => {
                MessageSuccess((data.byRejection === false ? '驳回' : '处理') + '成功');
                that.rejectCancel();
                that.clickCancel();
            }).finally(() => loading.close());
        },

    },
    async created() {
        this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
        this.paymentTypeList = this.paymentTypeList.filter(item => item.code !== 500101 && item.code !== 500102 && item.code !== 500106);
    },
    watch: {
        'formPublish.isForce'(value) {
            if (!value) {
                this.formPublish.depoBalanceRefund = 0;
            }
        }
    }
}
</script>

<style scoped>
.row {
    margin-bottom: 10px;
    padding: 10px 20px;
    border: 1px solid #D7D7D7;
}

.width300 {
    width: 300px;
}
.width200 {
    width: 200px;
}
.width100 {
    width: 100px;
}
.width250 {
    width: 250px;
}
.boder-right {
    border-right: 1px solid #D7D7D7;
}

.title {
    border-left: 5px #F5A623 solid;
    padding-left: 5px;
    margin: 15px 0;
    font-weight: 600;
}

.bg-E6E6E6 {
    background-color: #E6E6E6;
}

.bg-E2F5FF {
    background-color: #E2F5FF;
}

.bg-F8F8F8 {
    background-color: #F8F8F8;
}

.width33b {
    width: 50%;
}

.padding_0_30 {
    padding: 0 30px
}

.padding_10_30 {
    padding: 10px 30px;
}

.fw600 {
    font-weight: 600;
}
</style>